import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { _x } from '@wordpress/i18n';

Fancybox.defaults.l10n = {
  CLOSE: _x('Close', 'fancybox label', 'vtx'),
  NEXT: _x('Next', 'fancybox label', 'vtx'),
  PREV: _x('Previous', 'fancybox label', 'vtx'),
  MODAL: _x(
    'You can close this modal content with the ESC key',
    'fancybox label',
    'vtx'
  ),
  ERROR: _x(
    'Something went wrong, please try again later',
    'fancybox label',
    'vtx'
  ),
  IMAGE_ERROR: _x('Image not found', 'fancybox label', 'vtx'),
  ELEMENT_NOT_FOUND: _x('HTML element not found', 'fancybox label', 'vtx'),
  AJAX_NOT_FOUND: _x('Error Loading AJAX : Not Found', 'fancybox label', 'vtx'),
  AJAX_FORBIDDEN: _x('Error Loading AJAX : Forbidden', 'fancybox label', 'vtx'),
  IFRAME_ERROR: _x('Error Loading Page', 'fancybox label', 'vtx'),
  ITERATEZOOM: _x('Toggle zoom level', 'fancybox label', 'vtx'),
  TOGGLE_ZOOM: _x('Toggle zoom level', 'fancybox label', 'vtx'),
  TOGGLE_THUMBS: _x('Toggle thumbnails', 'fancybox label', 'vtx'),
  TOGGLE_SLIDESHOW: _x('Toggle slideshow', 'fancybox label', 'vtx'),
  TOGGLE_FULLSCREEN: _x('Toggle full-screen mode', 'fancybox label', 'vtx'),
  DOWNLOAD: _x('Download', 'fancybox label', 'vtx'),
};

Fancybox.bind('.lightbox-video__thumbnail', {});
